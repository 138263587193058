// 申遗承诺
import SHZT from './sn-table-column-shzt';
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'PZSJ',
                    label: '拍照时间',
                    minWidth: 135
                }, {
                    prop: 'JCMC',
                    label: '进程',
                    minWidth: 200,
                    showoverflowtooltip: true
                }, {
                    prop: 'LJ',
                    label: '照片',
                    minWidth: 128,
                    showFileBtn: true,
                }, {
                    prop: 'DEPNAME',
                    label: '采集部门',
                    minWidth: 130,
                },
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        selectData: [
            //    
        ],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export default selfData;